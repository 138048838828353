import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import classNames from "classnames";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import routes, { appRoute, createRequestRoute } from "shared/constants/routes";
import { CreateRequestContextProvider, SET_EDIT_USER_APP, PATCH_PERSONAL_DETAILS, SET_ACTIVE_STEP_INDEX, SET_APP_ID, useCreateRequestContext } from "./CreateRequestContext";
import { StateInspector } from "./StateInspector";
import { StepperControls } from "./components/StepperControls";
import { useEffect, useState } from "react";
import { API_URL } from "constants";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import { toast } from "react-toastify";
import useAuthHook from "hooks/useAuthHook";
import { useCreateNewAppMutation, useGetApplicationQuery } from "services/auth";
import { useTranslation } from "../../../contexts/TranslationContext";
import { useGetApp } from "./useGetApp";

const NavigationToolbar = () => {
	const navigate = useNavigate();
	const { state, dispatch } = useCreateRequestContext();
	const { language } = useTranslation()

	const previous = () => {
		if (state.activeStepIndex === 0) {
			return false;
		}

		// Special case:
		// ---
		// If at Document Details, and type of loan is Personal Finance
		// We should skip going back to Auto Loan Details
		if (state.activeStepIndex === 2 && !state?.loanDetails?.isAutofacility) {
			dispatch({
				type: SET_ACTIVE_STEP_INDEX,
				payload: 0,
			});
			navigate(createRequestRoute(state.steps[0].path) + `?appl_=${state.appId}`);

			return;
		}

		dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: state.activeStepIndex - 1 });
		navigate(createRequestRoute(state.steps[state.activeStepIndex - 1].path) + `?appl_=${state.appId}`);
	};

	const close = () => {
		navigate(appRoute(routes.HOME));
	};

	return (
		<div className='flex items-center justify-between mb-4'>
			{state.activeStepIndex > 0 && state.activeStepIndex < 7 && (
				<button onClick={previous} className='rounded-md border  h-10 w-10 bg-white border-gray-200 p-2 flex items-center justify-center hover:bg-gray-100 active:bg-gray-200' type='button'>
					{language.code == 'en' ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
				</button>
			)}
			{state.activeStepIndex < 7 && (
				<button onClick={close} className='rounded-md border  h-10 w-10 bg-white border-gray-200 p-2 flex items-center justify-center hover:bg-gray-100 active:bg-gray-200' type='button'>
					<CloseOutlined />
				</button>
			)}
		</div>
	);
};

const StepsProgress = () => {
	const { state } = useCreateRequestContext();
	const { translate } = useTranslation();
	const isAuto = state.loanDetails.subproductId == 250
	const steps = !isAuto ? state.steps.filter(step => step.path !== "auto-loan") : state.steps
	return (
		<div className='mb-4'>
			<div className='flex items-center justify-center gap-2'>
				{steps.map((_, i) => (
					<Tooltip key={i} title={state.steps[i].title}>
						<div
							className={classNames({
								"rounded w-full h-1": true,
								"bg-gray-200": isAuto ? i >= state.activeStepIndex : i >= state.activeStepIndex - 1,
								"bg-green-400": isAuto ? i < state.activeStepIndex : i < state.activeStepIndex - 1,
							})}></div>
					</Tooltip>
				))}
			</div>
		</div>
	);
};

const AppIdContainer = () => {
	const { state, dispatch } = useCreateRequestContext();
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const [createNewApp] = useCreateNewAppMutation();
	const { getUser } = useAuthHook();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const applId = searchParams.get("appl_");
	const { isLoading, error, refetch } = useGetApp(applId);

	const createApp = async () => {
		try {
			const user = getUser();

			if (!user) return;

			const res = await createNewApp({
				id: String(user?.IDB_USR_ID),
				idNumber: String(user?.USER_ID),
				idType: String(user?.USER_ID)[0] === "1" ? "NID" : "Q",
			}).unwrap();
			if (res?.query) {
				dispatch({ type: SET_APP_ID, payload: res?.query });
			}
		} catch (ex) {
		}
	};

	useEffect(() => {
		if (applId) {
			dispatch({ type: SET_APP_ID, payload: applId });
		} else {
			createApp(); // Create a new app if applId doesn't exist
		}
	}, [applId]);

	// useEffect(() => {
	// 	if (applicationData) {
	// 		getUserApp(applicationData)
	// 	}
	// }, [applicationData])


	if (fetching) {
		return (
			<div className='h-screen w-screen flex items-center justify-center'>
				<div className='text-xl'>Loading...</div>
			</div>
		);
	}

	return null;
};

export default function CreateRequest() {
	return (
		<CreateRequestContextProvider>
			<AppIdContainer />
			<section className='rounded min-h-16 mb-4 px-4 py-8 bg-white'>
				<NavigationToolbar />
				<StepsProgress />
				<Outlet />
				<StepperControls />
			</section>
		</CreateRequestContextProvider>
	);
}
